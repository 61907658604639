import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "Management",
    path: "accueil / nos métiers / management / Delivery Manager"
  },
  title: "Delivery Manager",
	missions:[
		{
		  text: "Animer et mettre en œuvre la production de plusieurs équipes, sur un périmètre multi-activités (Service Desk, Proximité…) et / ou multi client, sur un ou plusieurs sites" 
		},
		{
		  text: "Garantir l’atteinte des objectifs de production sur chacun de ses périmètres" 
		},
		{
		  text: "Encadrer et relayer la politique RH de l’entreprise" 
		},
		{
		  text: "Assurer et animer la relation clients" 
		},
	],
	key_skills: [
		{
		  text: "Décider" 
		},
		{
		  text: "Communiquer (interne, hiérarchie, client, équipes)" 
		},
		{
		  text: "Se positionner en leader" 
		},
		{
		  text: "Contrôler" 
		},
		{
		  text: "Faire preuve d’écoute" 
		},
		{
		  text: "Analyser et comprendre" 
		},
		{
		  text: "Anticiper" 
		},
	],
	prerequisites:[
		{
		  text: "Maitrise du mode contractuel de l’infogérance" 
		},
		{
		  text: "Connaissances techniques et organisationnelles de l’environnement de production" 
		},
		{
		  text: "Expression écrite" 
		},
		{
		  text: "Maitrise des techniques de management de proximité sur équipes de plus de 10 collaborateurs." 
		},
		{
		  text: "Formation supérieure et/ou une expérience de 3 ans minimum sur poste DM ou équivalent." 
		},
	],
	activities:[
		{
		  text: "Piloter et suivre les conditions opérationnelles de chaque contrat" 
		},
		{
		  text: "Définir les besoins et organiser les ressources des dispositifs de production, en cohérence avec les contrats commerciaux" 
		},
		{
		  text: "Organiser et faire évoluer les processus en cohérence sur les contextes multi-activités" 
		},
		{
		  text: "Analyser les indicateurs et mettre en œuvre les plans d’action" 
		},
		{
		  text: "Proposer et déployer des pistes d’amélioration continue du service" 
		},
		{
		  text: "Préparer et animer les instances de pilotage avec les clients" 
		},
		{
		  text: "Encadrer et accompagner les collaborateurs dans l’atteinte de leurs objectifs" 
		}
	]
}

const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;